import React from 'react'
import PropTypes from "prop-types";
import { Modal } from 'reactstrap';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CardComponent from './CardComponent';

// const stripePromise = loadStripe('pk_live_51JrhmkEabK4QG6eiQzCfQSEpR6WksEskYjRxETM0cIcwTg2SPNBXfnyZFx8WInPGIRokrNIqvJOrdd3NQpHr9SFm00cpALwk0q');
let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    // stripePromise = loadStripe('pk_live_51JrhmkEabK4QG6eiQzCfQSEpR6WksEskYjRxETM0cIcwTg2SPNBXfnyZFx8WInPGIRokrNIqvJOrdd3NQpHr9SFm00cpALwk0q');
    stripePromise = loadStripe('pk_live_51MAa39ENvFhvO3XBprvIWL1qmldIMkqZLrPPk4I7eZAFDsym2Vqtjd8ud7DhlwtxB70jL4jN4b6oINoZo9lrexnV002BWrWvvM');
  }
  return stripePromise;
};

const Payment = ({ data, modal, toggle, refresh }) => {

  // const handleSubmit = async() => {
  //   setLoading(true);
  //   if (data) {
  //     var res = await put(`appointment/${data.id}`, {
  //       date,
  //       patient,
  //       doctor,
  //       meeting,
  //       message,
  //     });
  //   }else{
  //     var res = await post('appointment', {
  //       date,
  //       patient,
  //       doctor,
  //       meeting,
  //       message,
  //     });
  //   }
  //   showToast(res.message, !res.status && 'error')
  //   setLoading(false)
  //   if (!res.status) {
  //     setErrors(res.errors)
  //     return;
  //   }
  //   toggle()
  //   refresh()
  // }
  return (
    <Modal
      size="lg"
      toggle={() => toggle()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          Invoice Payment
        </h5>
        <button
          onClick={() => toggle()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">

        <div className="p-4 border">
        <Elements stripe={getStripe()}>
          <CardComponent data={data} toggle={toggle} refresh={refresh} />
        </Elements>
        </div>
      </div>
    </Modal>
  )
}

Payment.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  refresh: PropTypes.func,
  toggle: PropTypes.func,
};

export default Payment;