import { del, get } from 'helpers/api_helper';
import { formatDate, getRole } from 'helpers/util';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Pagination } from 'react-laravel-paginex';
import { MetaTags } from 'react-meta-tags';
import Breadcrumbs from "components/Common/Breadcrumb";
import { Badge, Button, Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, Spinner, UncontrolledButtonDropdown } from 'reactstrap';
import CreateUpdateDoctor from './CreateUpdateDoctor';
import DeleteConfirmation from 'components/Alert/DeleteConfirmation';
import { useQuery } from '@tanstack/react-query';
import api from 'helpers/api';

const Doctor = () => {
  const [id, setId] = useState('')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null)
  const [showDelete, setShowDelete] = useState(false)
  const { data, refetch: getData, isLoading } = useQuery(['doctors', search, page], () => api.getDoctor({
    params: {
      page: page,
      search: search,
    }
  }), {
    refetchOnWindowFocus: false,
  });

  const deleteAction = async () => {
    const patient = await del(`/doctors/${id}`);
    setShowDelete(false)
    getData();
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  const handleCreateModal = () => {
    toggle();
    setModalData(null)
  };

  const handleUpdateModal = (data) => {
    toggle();
    setModalData(data)
  };

  const selectRow = {
    mode: "checkbox",
    hideSelectColumn: true,
    hideSelectAll: true,
  };

  //pagination customization
  const pageOptions = {
    page: 1,
    sizePerPage: 10,
    pageStartIndex: 1,
    totalSize: data?.total, // replace later with size(doctors),
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "id",
      doctor: "desc",
      order: 'desc',
    },
  ];

  const EcommerceDoctorColumns = toggleModal => [
    {
      dataField: "doctor.fullname",
      text: "Doctor",
      sort: true,
    },
    {
      dataField: "specialist.specialist_name",
      text: "Specialist",
      sort: true,
    },
    {
      dataField: "doctor.phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "doctor.email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Join Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => formatDate(row.created_at),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Badge
          className={`font-size-12 badge-soft-${row.user.status == 'ACTIVE' ? 'success' : 'danger'}`}
          color={`font-size-12 badge-soft-${row.user.status == 'ACTIVE' ? 'success' : 'danger'}`}
          pill
        >
          {row.user?.status}
        </Badge>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      hidden: getRole() == 'Staff' ? true : false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <UncontrolledButtonDropdown direction="start">
            <DropdownToggle caret>
              Action
            </DropdownToggle>
            <DropdownMenu>
              {/* {(getRole() == 'Admin' || getRole() == 'Staff') && (
              )} */}
              {getRole() == 'Admin' && (
                <>
                  <DropdownItem onClick={() => handleUpdateModal(row)}>
                    Edit
                  </DropdownItem>
                  <DropdownItem onClick={() => {
                    setId(row.user.id);
                    setShowDelete(true);
                  }}>
                    Delete
                  </DropdownItem>
                </>
              )}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </>
      ),
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>ADHD Centre</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Doctor" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <input
                            placeholder="Search..."
                            className="form-control"
                            onInput={(e) => {
                              setSearch(e.target.value);
                            }}
                            value={search}
                            type="text"
                          />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    {getRole() == 'Admin' && (
                      <Col sm="8">
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2"
                            onClick={() => handleCreateModal()}
                          >
                            <i className="mdi mdi-plus me-1" />
                            Add New Doctor
                          </Button>
                        </div>
                      </Col>
                    )}
                  </Row>
                  {isLoading ? (
                    <Spinner className="ms-2 spinner-loading" color="success" />
                  ) : (
                    <Row>
                      <Col sm="12">
                          {data && (
                            <PaginationProvider
                              pagination={paginationFactory(pageOptions)}
                              keyField="id"
                              columns={EcommerceDoctorColumns()}
                              data={data.data}
                            >
                              {({ paginationProps, paginationTableProps }) => (
                                <ToolkitProvider
                                  keyField="id"
                                  data={data.data}
                                  columns={EcommerceDoctorColumns()}
                                  bootstrap4
                                  search
                                >
                                  {toolkitProps => (
                                    <React.Fragment>
                                      <Row>
                                        <Col xl="12">
                                          <div className="table-responsive">
                                            <BootstrapTable
                                              keyField="id"
                                              responsive
                                              bordered={false}
                                              striped={false}
                                              defaultSorted={defaultSorted}
                                              selectRow={selectRow}
                                              classes={
                                                "table align-middle table-nowrap table-check"
                                              }
                                              headerWrapperClasses={"table-light"}
                                              {...toolkitProps.baseProps}
                                              {...paginationTableProps}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row className="align-items-md-center mt-30">
                                        <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                          <Pagination options={{ numberButtonClass: "page-item d-flex" }}changePage={(e) => setPage(e.page)} data={data} />
                                        </Col>
                                      </Row>
                                    </React.Fragment>
                                  )}
                                </ToolkitProvider>
                              )}
                            </PaginationProvider>
                          )}
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CreateUpdateDoctor modal={modal} toggle={toggle} handleCreateModal={handleCreateModal} data={modalData} refresh={getData} />
      <DeleteConfirmation showDelete={showDelete} setShowDelete={() => setShowDelete(false)} deleteAction={deleteAction} />
    </React.Fragment>
  )
}

export default Doctor;