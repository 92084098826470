import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Card, CardBody, Col, Form, Label, Modal, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from 'reactstrap';
import { getRole, showToast } from 'helpers/util';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import { FormProvider, useForm } from 'react-hook-form';
import Profile from './Detail/components/Profile';
import PersonalDetail from './Detail/components/PersonalDetail';
import EmergencyContact from './Detail/components/EmergencyContact';
import Document from './Detail/components/Document';
import classnames from 'classnames';
import Confirmation from 'components/Alert/Confirmation';
import AcceptPatient from './AcceptPatient';

const ShowPatientModal = ({ data, toggle, modal, handleCreateModal, id, tabs = "1" }) => {
  const methods = useForm();
  const [showConfirm, setShowConfirm] = useState(false)
  const [showDecline, setShowDecline] = useState(false)
  const [customActiveTab, setcustomActiveTab] = useState(tabs ?? "1");
  const [errors, setErrors] = useState([])

  const { data: dataPatient, refetch, isLoading } = useQuery(['patient-detail', id], () => api.getPatientDetail(id), {
    refetchOnWindowFocus: false,
    enabled: !!id
  });

  const acceptPatient = async (accept = true, doctors) => {
    const patient = await post(`/accept-patient/${id}`, {
      accept,
      doctors
    });
    setShowAccept(false)
    getData();
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  useEffect(() => {
    methods.reset({
      ...dataPatient,
      ...dataPatient?.user,
      emergency_name: dataPatient?.user.emergency_contact?.first_name,
      emergency_last_name: dataPatient?.user.emergency_contact?.last_name,
      emergency_email: dataPatient?.user.emergency_contact?.email,
      emergency_phone: dataPatient?.user.emergency_contact?.phone,
      emergency_relationship: dataPatient?.user.emergency_contact?.relationship,
      patient_type: dataPatient?.type_patients_id,
      patient_type_problem: dataPatient?.type_presenting_problem_id,
      doctor: dataPatient?.doctors.map(val => val.id),
    } ?? {});
  }, [dataPatient])

  return (
    <>
      <Modal
        toggle={() => handleCreateModal()}
        isOpen={modal}
        centered
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            Detail Patient
          </h5>
          <button
            onClick={() => handleCreateModal()}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{padding: 0}}>
          {isLoading && !dataPatient ? (
            <Spinner className="ms-2 spinner-loading" color="success" />
          ) : (
            <>
              <Card style={{ minHeight: 500 }}>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <Nav pills className="flex-column">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: customActiveTab === "1",
                            })}
                            onClick={() => {
                              toggleCustom("1");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="fas fa-home"></i>
                            </span>
                            <span className="d-none d-sm-block">Main Profile</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: customActiveTab === "2",
                            })}
                            onClick={() => {
                              toggleCustom("2");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-user"></i>
                            </span>
                            <span className="d-none d-sm-block">Personal Detail</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: customActiveTab === "3",
                            })}
                            onClick={() => {
                              toggleCustom("3");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-user"></i>
                            </span>
                            <span className="d-none d-sm-block">Emergency Contact</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: customActiveTab === "4",
                            })}
                            onClick={() => {
                              toggleCustom("4");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">Document</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>

                    <Col md="10">
                      <TabContent
                        activeTab={customActiveTab}
                        className="p-3 text-muted"
                      >
                        <FormProvider {...methods} autoComplete="off">
                          <TabPane tabId="1">
                            <Profile data={dataPatient} refetch={refetch} errors={errors} disableInput={true} />
                          </TabPane>
                          <TabPane tabId="2">
                            <PersonalDetail data={dataPatient} refetch={refetch} errors={errors} disableInput={true} />
                          </TabPane>
                          <TabPane tabId="3">
                            <EmergencyContact data={dataPatient} refetch={refetch} errors={errors} disableInput={true} />
                          </TabPane>
                          <TabPane tabId="4">
                            <Document data={dataPatient} refetch={refetch} errors={errors} />
                          </TabPane>
                        </FormProvider>
                      </TabContent>
                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </>
          )}
        </div>
        <div className="modal-footer d-md-flex justify-content-md-between">
          <button type="button" className="btn btn-danger w-md" onClick={(e) => {
            e.preventDefault()
            setShowDecline(true)
          }}>
            Decline
          </button>

          <button type="button" className="btn btn-success w-md" onClick={(e) => {
            e.preventDefault()
            setShowConfirm(true)
          }}>
            Accept
          </button>
        </div>
      </Modal>
      <Confirmation showConfirm={showDecline} setShowConfirm={() => setShowDecline(false)} action={acceptPatient} accept={false} />
      <AcceptPatient showAccept={showConfirm} setShowAccept={() => setShowConfirm(false)} action={acceptPatient} />
    </>
  )
}

ShowPatientModal.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  handleCreateModal: PropTypes.func,
  refetch: PropTypes.func,
  id: PropTypes.number,
  tabs: PropTypes.string,
};

export default ShowPatientModal;