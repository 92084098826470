import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row, Spinner
} from "reactstrap";

import { get } from "helpers/api_helper";
import { currency, formatDateTime, getRole } from "helpers/util";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

const DashboardStaff = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(async () => {
    if (!data) {
      const dashboard = await get('/dashboard');
      setLoading(false)
      setData(dashboard.data)
    }
  }, [])

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
      order: 'desc',
    },
  ];

  const selectRow = {
    mode: "checkbox",
    hideSelectColumn: true,
    hideSelectAll: true,
  };

  //pagination customization
  const pageOptions = {
    page: 1,
    sizePerPage: 10,
    pageStartIndex: 1,
    totalSize: data?.total, // replace later with size(orders),
    custom: true,
  };

  const EcommerceOrderColumns = toggleModal => [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link to={`/invoices/${row.id}`}>#{row.id}</Link>
      ),
    },
    {
      dataField: "user.fullname",
      text: "Client",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link to={`/patients/${row.user?.id}`}>{row.user?.fullname}</Link>
      ),
    },
    {
      dataField: "doctor.user.fullname",
      text: "Doctor",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <span className="text-center">{row.doctor ? row.doctor.user.fullname : '-'}</span>
      ),
    },
    {
      dataField: "appointment.date",
      text: "Appointment Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => row.appointment ? formatDateTime(row.appointment?.date) : '-',
    },
    {
      dataField: "total",
      text: "Total",
      sort: true,
      formatter: (cellContent, row) => currency(row.total),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Badge
          className={`font-size-12 badge-soft-${row.status == 'PAID' ? 'success' : row.status == 'PARTIALLY' ? 'warning' : 'danger'}`}
          color={row.status}
          pill
        >
          {row.status}
        </Badge>
      ),
    },
    {
      dataField: "created_at",
      text: "Created",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => formatDateTime(row.created_at),
    },
    {
      dataField: "approved_by",
      text: "Approved By",
      sort: true,
      hidden: getRole() == 'Patient' ? true : false
    },
  ];

  return (
    <>
      {loading ? (
        <Spinner className="ms-2 spinner-loading" color="success" />
      ) : (
        <>
          <Row>
            <Col md={6}>
              <Link to="/patients?tabs=2">
                <Card body>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h4 className="text-muted fw-large">
                        New Patient Request
                      </h4>
                      <h2 className="mb-0">{data?.new_patient} Patients</h2>
                    </div>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-success">
                        <i className='bx bxs-right-arrow-circle font-size-24'></i>
                      </span>
                    </div>
                  </div>
                </Card>
              </Link>
            </Col>
            <Col md={6}>
              <Link to="/patients?tabs=3">
                <Card body>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h4 className="text-muted fw-large">
                        Existing Patient Request
                      </h4>
                      <h2 className="mb-0">{data?.exist_patient} Patients</h2>
                    </div>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-success">
                        <i className='bx bxs-right-arrow-circle font-size-24'></i>
                      </span>
                    </div>
                  </div>
                </Card>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Link to="/appointments">
                <Card body>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h4 className="text-muted fw-large">
                        Today Appointments
                      </h4>
                      <h2 className="mb-0">{data?.today_appointment} Appointments</h2>
                    </div>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-success">
                        <i className='bx bxs-right-arrow-circle font-size-24'></i>
                      </span>
                    </div>
                  </div>
                </Card>
              </Link>
            </Col>
            <Col md={4}>
              <Link to="/appointments">
                <Card body>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h4 className="text-muted fw-large">
                        Upcoming Appointments
                      </h4>
                      <h2 className="mb-0">{data?.upcoming_appointment} Appointments</h2>
                    </div>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-success">
                        <i className='bx bxs-right-arrow-circle font-size-24'></i>
                      </span>
                    </div>
                  </div>
                </Card>
              </Link>
            </Col>
            <Col md={4}>
              <Link to="/appointments">
                <Card body>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h4 className="text-muted fw-large">
                        Finish Appointments
                      </h4>
                      <h2 className="mb-0">{data?.finish_appointment} Appointments</h2>
                    </div>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-success">
                        <i className='bx bxs-right-arrow-circle font-size-24'></i>
                      </span>
                    </div>
                  </div>
                </Card>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Link to="/patients?tabs=4">
                <Card body>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h4 className="text-muted fw-large">
                        Script Reminder 2 Month Away
                      </h4>
                      <h2 className="mb-0">{data?.refExpMonthTotal} Patients</h2>
                    </div>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-success">
                        <i className='bx bxs-right-arrow-circle font-size-24'></i>
                      </span>
                    </div>
                  </div>
                </Card>
              </Link>
            </Col>
            <Col md={4}>
              <Link to="/patients?tabs=4">
                <Card body>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h4 className="text-muted fw-large">
                        Script Reminder 2 Weeks Away
                      </h4>
                      <h2 className="mb-0">{data?.refExpWeekTotal} Patients</h2>
                    </div>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-success">
                        <i className='bx bxs-right-arrow-circle font-size-24'></i>
                      </span>
                    </div>
                  </div>
                </Card>
              </Link>
            </Col>
            <Col md={4}>
              <Link to="/patients?tabs=5">
                <Card body>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h4 className="text-muted fw-large">
                        Referral Reminder 2 Month Away
                      </h4>
                      <h2 className="mb-0">{data?.refExpTotal} Patients</h2>
                    </div>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-success">
                        <i className='bx bxs-right-arrow-circle font-size-24'></i>
                      </span>
                    </div>
                  </div>
                </Card>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {loading ? (
                    <Spinner className="ms-2 spinner-loading" color="success" />
                  ) : (
                    <>
                      <CardTitle>Last 10 Invoice</CardTitle>
                      {data && data.lastInvoice && (
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="id"
                          columns={EcommerceOrderColumns()}
                          data={data.lastInvoice}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="id"
                              data={data.lastInvoice}
                              columns={EcommerceOrderColumns()}
                              bootstrap4
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField="id"
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          selectRow={selectRow}
                                          classes={
                                            "table align-middle table-nowrap table-check"
                                          }
                                          headerWrapperClasses={"table-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  {/* <Row className="align-items-md-center mt-30">
                                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                                <Pagination options={{ numberButtonClass: "page-item d-flex" }}changePage={(e) => getData(e.page)} data={data} />
                                              </Col>
                                            </Row> */}
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      )
                      }
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default React.memo(DashboardStaff);
