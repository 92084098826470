import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import PropTypes from 'prop-types';
import React, { useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import { Col, Label, Row } from 'reactstrap';
import Select from "react-select";

const AcceptPatient = ({ showAccept, setShowAccept, action }) => {
  const [success, setSuccess] = useState(false)
  const [doctor, setDoctor] = useState([])
  const [search, setSearch] = useState('')
  const { data: doctors, isLoading } = useQuery(['get-doctor', search], () => api.getSelectDoctor({
    params: {
      search: search,
    }
  }), {
    refetchOnWindowFocus: false
  });

  return (
    <>
      {success && (
        <SweetAlert
          success
          timeout={1500}
          title="Processed"
          onConfirm={() => {
            setSuccess(false)
          }}
        >
          Your request has been processed.
        </SweetAlert>
      )}

      {showAccept && (
        <SweetAlert
          title="Do you want to accept this patient?"
          showCancel
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            action(true, doctor)
            setShowAccept(false)
            setSuccess(true)
          }}
          onCancel={() => { setShowAccept(false) }}
          type={'controlled'}
          dependencies={[doctors, isLoading, setDoctor]}
        >
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="formrow-doctor-Input">Select Doctor</Label>
                <Select
                  maxMenuHeight={200}
                  options={doctors}
                  isMulti
                  autoComplete="off"
                  onInputChange={(e) => setSearch(e)}
                  onChange={(e) => {
                    setDoctor(e.map(val => val.value))
                  }}
                  isLoading={isLoading}
                  classNamePrefix="select2-selection"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
              </div>
            </Col>
          </Row>
        </SweetAlert>
      )}
    </>
  );
}

AcceptPatient.propTypes = {
  showAccept: PropTypes.bool,
  setShowAccept: PropTypes.func,
  action: PropTypes.func,
  accept: PropTypes.bool,
  title: PropTypes.string,
  actionReject: PropTypes.func,
};

export default AcceptPatient