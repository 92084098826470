import React from 'react'
import { Modal, Row } from 'reactstrap'
import PropTypes from 'prop-types'

const ModalNotes = ({ modal, toggle, data }) => {
  return (
    <Modal
      toggle={() => toggle()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          Notes
        </h5>
        <button
          onClick={() => toggle()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="d-md-flex justify-content-md-between">
          <b>Invoice Notes</b>
          <p dangerouslySetInnerHTML={{ __html: data?.notes ?? '' }}></p>
        </div>
        {data?.payment.map((item, i) => (
          <div className="d-md-flex justify-content-md-between" key={i}>
            <b>Payment {i + 1} Notes</b>
            <p>{item.notes ?? '-'}</p>
          </div>
        ))}
      </div>
    </Modal>
  )
}

ModalNotes.propTypes = {
  modal: PropTypes.bool,
  data: PropTypes.object,
  toggle: PropTypes.func,
};

export default React.memo(ModalNotes)