import React from "react";
import MetaTags from 'react-meta-tags';
import {
  Container
} from "reactstrap";

import { getRole } from "helpers/util";
import DashboardDoctor from "./DashboardDoctor";
import DashboardManager from "./DashboardManager";
import DashboardStaff from "./DashboardStaff";

const Dashboard = () => {

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>ADHD Centre</title>
        </MetaTags>
        <Container fluid>
          <h4>Dashboard</h4>
          {getRole() == 'Admin' || getRole() == 'Manager' ? (
            <DashboardManager />
          ) : getRole() == 'Staff' ? (
            <DashboardStaff />
          ) : getRole() == 'Doctor' && (
            <DashboardDoctor />
          )}
          </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard;
