import { del, get } from 'helpers/api_helper';
import { currency, formatDate, getRole } from 'helpers/util';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Pagination } from 'react-laravel-paginex';
import { MetaTags } from 'react-meta-tags';
import Breadcrumbs from "components/Common/Breadcrumb";
import { Badge, Button, Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, Spinner, UncontrolledButtonDropdown } from 'reactstrap';
import CreateUpdateProduct from './CreateUpdateProduct';
import DeleteConfirmation from 'components/Alert/DeleteConfirmation';

const Product = () => {
  const [loading, setLoading] = useState(true)
  const [id, setId] = useState('')
  const [data, setData] = useState(null)
  const [products, setProducts] = useState([])
  const [search, setSearch] = useState('')
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null)
  const [showDelete, setShowDelete] = useState(false)

  const deleteAction = async () => {
    const product = await del(`/products/${id}`);
    setShowDelete(false)
    getData();
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  const handleCreateModal = () => {
    toggle();
    setModalData(null)
  };

  const handleUpdateModal = (data) => {
    toggle();
    setModalData(data)
  };

  const getData = useCallback(async (page = 1, search) => {
    setLoading(true)
    const product = await get('/products', {
      params: {
        page: page,
        search: search,
      }
    });
    setData(product);
    setProducts(product.data);
    setLoading(false)
  }, []);

  const selectRow = {
    mode: "checkbox",
    hideSelectColumn: true,
    hideSelectAll: true,
  };

  //pagination customization
  const pageOptions = {
    page: 1,
    sizePerPage: 10,
    pageStartIndex: 1,
    totalSize: data?.total, // replace later with size(products),
    custom: true,
  };

  useEffect(async () => {
    getData();
  }, []);

  const handleSearch = useCallback(debounce((page, search) => {
    getData(page, search)
  }, 800), [])

  const defaultSorted = [
    {
      dataField: "id",
      product: "desc",
      order: 'desc',
    },
  ];

  const EcommerceProductColumns = toggleModal => [
    {
      dataField: "sku",
      text: "SKU",
      sort: true,
    },
    {
      dataField: "name",
      text: "Product Name",
      sort: true,
    },
    {
      dataField: "price",
      text: "Price",
      sort: true,
      formatter: (cellContent, row) => currency(row.price),
    },
    {
      dataField: "tax_type",
      text: "Tax Type",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Created At",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => formatDate(row.created_at),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <UncontrolledButtonDropdown direction="start">
            <DropdownToggle caret>
              Action
            </DropdownToggle>
            <DropdownMenu>
              {(getRole() == 'Admin' || getRole() == 'Staff') && (
                <DropdownItem onClick={() => handleUpdateModal(row)}>
                  Edit
                </DropdownItem>
              )}
              {getRole() == 'Admin' && (
                <DropdownItem onClick={() => {
                  setId(row.id);
                  setShowDelete(true);
                }}>
                  Delete
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </>
      ),
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>ADHD Centre</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Product" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {loading ? (
                    <>
                      <Row className="mb-2">
                        <Col sm="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <input
                                placeholder="Search..."
                                className="form-control"
                                onInput={(e) => {
                                  setSearch(e.target.value);
                                  handleSearch(1, e.target.value)
                                }}
                                value={search}
                                type="text"
                              />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <Col sm="8">
                          <div className="text-sm-end">
                            <Button
                              type="button"
                              color="success"
                              className="btn-rounded  mb-2 me-2"
                              onClick={handleCreateModal}
                            >
                              <i className="mdi mdi-plus me-1" />
                              Add New Product
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <Spinner className="ms-2 spinner-loading" color="success" />
                    </>
                  ) : (
                    <Row>
                      <Col sm="12">
                          {data && products && (
                            <PaginationProvider
                              pagination={paginationFactory(pageOptions)}
                              keyField="id"
                              columns={EcommerceProductColumns()}
                              data={products}
                            >
                              {({ paginationProps, paginationTableProps }) => (
                                <ToolkitProvider
                                  keyField="id"
                                  data={products}
                                  columns={EcommerceProductColumns()}
                                  bootstrap4
                                  search
                                >
                                  {toolkitProps => (
                                    <React.Fragment>
                                      <Row className="mb-2">
                                        <Col sm="4">
                                          <div className="search-box me-2 mb-2 d-inline-block">
                                            <div className="position-relative">
                                              <input
                                                placeholder="Search..."
                                                className="form-control"
                                                onInput={(e) => {
                                                  setSearch(e.target.value);
                                                  handleSearch(1, e.target.value)
                                                }}
                                                value={search}
                                                type="text"
                                              />
                                              <i className="bx bx-search-alt search-icon" />
                                            </div>
                                          </div>
                                        </Col>
                                        <Col sm="8">
                                          <div className="text-sm-end">
                                            <Button
                                              type="button"
                                              color="success"
                                              className="btn-rounded  mb-2 me-2"
                                              onClick={handleCreateModal}
                                            >
                                              <i className="mdi mdi-plus me-1" />
                                              Add New Product
                                            </Button>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xl="12">
                                          <div className="table-responsive">
                                            <BootstrapTable
                                              keyField="id"
                                              responsive
                                              bproducted={false}
                                              striped={false}
                                              defaultSorted={defaultSorted}
                                              selectRow={selectRow}
                                              classes={
                                                "table align-middle table-nowrap table-check"
                                              }
                                              headerWrapperClasses={"table-light"}
                                              {...toolkitProps.baseProps}
                                              {...paginationTableProps}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row className="align-items-md-center mt-30">
                                        <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                          <Pagination options={{ numberButtonClass: "page-item d-flex" }}changePage={(e) => getData(e.page)} data={data} />
                                        </Col>
                                      </Row>
                                    </React.Fragment>
                                  )}
                                </ToolkitProvider>
                              )}
                            </PaginationProvider>
                          )}
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <CreateUpdateProduct modal={modal} toggle={toggle} handleCreateModal={handleCreateModal} data={modalData} refresh={getData} />
        <DeleteConfirmation showDelete={showDelete} setShowDelete={() => setShowDelete(false)} deleteAction={deleteAction} />
      </div>
    </React.Fragment>
  )
}

export default React.memo(Product);