import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { Form, Input, Label, Modal, Row } from 'reactstrap';
import { post, put } from 'helpers/api_helper';
import { showToast } from 'helpers/util';

const CreateUpdateType = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [errors, setErrors] = useState([])

  useEffect(() => {
    if (data) {
      setName(data.name_type)
    }else{
      setName('')
    }
  }, [data])

  const handleSubmit = async() => {
    setLoading(true);
    if (data) {
      var res = await put(`type-patient/${data.id}`, {
        name,
      });
    }else{
      var res = await post('type-patient', {
        name,
      });
    }
    showToast(res.message, !res.status && 'error')
    setLoading(false)
    if (!res.status) {
      setErrors(res.errors)
      return;
    }
    toggle()
    refresh()
  }

  return (
    <Modal
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? 'Update Type' : 'Create Type'}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
          }}>
          <div className="mb-3">
            <Label htmlFor="formrow-Name">Name</Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Name"
              id="formrow-Name"
              value={name}
              onInput={(e) => setName(e.target.value)}
            />
            {errors.name && <span className="form-text text-danger">{errors.name[0]}</span>}
          </div>

          <div className="d-md-flex justify-content-md-end">
            <button type="submit" className="btn btn-primary w-md" disabled={loading}>
              {loading ? (
                <>
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                  Loading
                </>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

CreateUpdateType.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
};

export default React.memo(CreateUpdateType);