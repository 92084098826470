import React from 'react'
import { Col, Label, Row } from 'reactstrap'
import PropTypes from "prop-types";
import Select from "react-select";
import ReferralLetter from 'pages/Patient/components/ReferralLetter';
import ScriptReminder from 'pages/Patient/components/ScriptReminder';
import Other from 'pages/Patient/components/Other';
import DoctorDocument from 'pages/Patient/components/DoctorDocument';

const Document = ({ data, refetch, errors }) => {
  return (
    <Row>

      <Col xl="6">
        <ReferralLetter data={data} refetch={refetch} />
      </Col>

      <Col xl="6">
        <ScriptReminder data={data} refetch={refetch} />
      </Col>

      <Col xl="6">
        <DoctorDocument data={data} refetch={refetch} />
      </Col>

      <Col xl="6">
        <Other data={data} refetch={refetch} />
      </Col>
    </Row>
  )
}

Document.propTypes = {
  data: PropTypes.object,
  refetch: PropTypes.func,
  errors: PropTypes.object,
};

export default React.memo(Document)