import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";

// import Component
import RecentFile from "./RecentFile";
import MenuLeftBar from "./MenuLeftBar";

const PatientMenu = () => {
  const [active, setActive] = useState(1)

  return (
    <div className="d-xl-flex">
      <div className="w-100">
        <div className="d-md-flex">
          <MenuLeftBar active={active} setActive={setActive} />
          <div className="w-100">
            <Card>
              <CardBody>
                <RecentFile />
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
export default React.memo(PatientMenu)
