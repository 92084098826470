import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import Select from "react-select";
import { Col, Form, Input, Label, Modal, Row } from 'reactstrap';
import { formatDateTimeInput, showToast } from 'helpers/util';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import { useForm } from 'react-hook-form';

const DocumentModal = ({ data, toggle, modal, handleCreateModal, refetch, type }) => {
  const [errors, setErrors] = useState([])

  const { register, getValues, setValue, watch, handleSubmit, reset, formState: { formErrors } } = useForm({
    defaultValues: {
      document_type: 'Document'
    }
  });

  const { mutate, isLoading: submitLoading } = useMutation((params) => api.addDocument(params), {
    onSuccess: (res) => {
      if (res.status) {
        showToast(res.message, !res.status && 'error')
        refetch()
        toggle()
      } else {
        if (res.errors) {
          setErrors(res.errors)
        }else{
          showToast(res.message, 'error')
        }
      }
    },
    onError: (err) => {
      console.log(err)
      showToast('Failed to submit', 'error')
    }
  });

  const onSubmit = () => {
    var formData = new FormData();
    if (getValues('files')?.length) {
      for (let i = 0; i < getValues('files').length; i++) {
        formData.set(`files[${i}]`, getValues('files')[i]);
      }
    }
    formData.set('user_id', data.user.id)
    formData.set('type', type)
    formData.set('date_expired', getValues('date_expired'))
    formData.set("document_type", getValues("document_type"))
    formData.set("token", getValues("token"))
    mutate(formData)
  };

  const onFileChange = (e) => {
    if (e.target.files.length > 5) {
      showToast('Maximum upload is up to 5 files', 'error')
      e.target.value = null;
    }else{
      setValue('files', e.target.files)
    }
  }

  useEffect(() => {
    if (data) {
      if (type == 'referral_letter') {
        setValue('date_expired', data?.user?.requirement?.referral_expired)
      } else if (type == 'medicare_file') {
        setValue('date_expired', data?.user?.requirement?.medicare_expired)
      }
    }
    setErrors([]);
  }, [data])

  return (
    <Modal
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
      contentClassName="h-50"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Upload Document</h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={e => {
            e.preventDefault()
            onSubmit()
          }}
        >
          {type == "medicare_file" ? (
            <>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-files-Input">Type</Label>
                    <select
                      className="form-control"
                      {...register("document_type")}
                    >
                      <option value="Document">Document</option>
                      <option value="Token">Token</option>
                    </select>
                  </div>
                </Col>
              </Row>
              {watch("document_type") == "Document" ? (
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-files-Input">Document</Label>
                      <Input
                        className="form-control"
                        type="file"
                        id="formFile"
                        onChange={e => onFileChange(e)}
                        multiple
                      />
                      {errors.files && (
                        <span className="form-text text-danger">
                          {errors.files[0]}
                        </span>
                      )}
                      {Array.from({ length: 5 }).map(
                        (item, i) =>
                          errors[`files.${i}`] && (
                            <span className="form-text text-danger">
                              {errors[`files.${i}`][0]}
                            </span>
                          )
                      )}
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-files-Input">Token</Label>
                      <input
                        className="form-control"
                        type="text"
                        id="formFile"
                        {...register("token")}
                      />
                      {errors.token && (
                        <span className="form-text text-danger">
                          {errors.token[0]}
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
              )}
            </>
          ) : (
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <Label htmlFor="formrow-files-Input">Document</Label>
                  <Input
                    className="form-control"
                    type="file"
                    id="formFile"
                    onChange={e => onFileChange(e)}
                    multiple
                  />
                  {errors.files && (
                    <span className="form-text text-danger">
                      {errors.files[0]}
                    </span>
                  )}
                  {Array.from({ length: 5 }).map(
                    (item, i) =>
                      errors[`files.${i}`] && (
                        <span className="form-text text-danger">
                          {errors[`files.${i}`][0]}
                        </span>
                      )
                  )}
                </div>
              </Col>
            </Row>
          )}
          {type == "referral_letter" || type == "medicare_file" ? (
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <Label htmlFor="formrow-files-Input">Date Expired</Label>
                  <Input
                    className="form-control"
                    type="date"
                    max={new Date().getFullYear() + "-12-31"}
                    defaultValue={
                      type == "referral_letter"
                        ? data.user.requirement?.referral_expired
                        : data.user.requirement?.medicare_expired
                    }
                    onChange={e => setValue("date_expired", e.target.value)}
                  />
                  {errors.files && (
                    <span className="form-text text-danger">
                      {errors.files[0]}
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          ) : null}
        </Form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button
            type="submit"
            className="btn btn-primary w-md"
            disabled={submitLoading}
            onClick={e => {
              e.preventDefault()
              onSubmit()
            }}
          >
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

DocumentModal.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  handleCreateModal: PropTypes.func,
  refetch: PropTypes.func,
  type: PropTypes.string,
};

export default React.memo(DocumentModal);