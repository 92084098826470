import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from "prop-types";
import Select from "react-select";
import { Col, Form, Input, Label, Modal, Row } from 'reactstrap';
import { formatDateInput, formatDateTimeInput, showToast } from 'helpers/util';
import api from 'helpers/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import Datetime from 'react-datetime';
import moment from 'moment';

const CreateUpdateAppointment = ({ data, toggle, modal, handleCreateModal, refresh, defaultPatient = null }) => {
  const [searchDoctor, setSearchDoctor] = useState('')
  const [searchPatient, setSearchPatient] = useState('')
  const [errors, setErrors] = useState([])

  const { getValues, setValue, handleSubmit, watch, reset, formState: { formErrors } } = useForm({
    defaultValues: {
      id: data ? data.id : null,
      ...data,
    }
  });

  const { data: doctorData, loadingDoctor, refetch: fetchDoctor } = useQuery(['get-doctor', searchDoctor], () => api.getSelectDoctor({
    params: {
      search: searchDoctor,
      patient_id: getValues('patient'),
      for: 'Patient',
    }
  }), {
    refetchOnWindowFocus: false,
    enabled: !!getValues('patient')
  });

  const { data: patientData, loadingPatient } = useQuery(['get-patient', searchPatient], () => api.getSelectPatient({
    params: {
      search: searchPatient,
      for: 'Appointment',
    }
  }), {
    refetchOnWindowFocus: true
  });

  const { data: availability, loadingAvailability } = useQuery(['check-availability', getValues('date'), getValues('doctor')], () => api.checkAppointmentAvailability({
    params: {
      doctor: getValues('doctor'),
      date: getValues('date'),
    }
  }), {
    refetchOnWindowFocus: false,
    enabled: !!(getValues('doctor') && getValues('date'))
  });

  const { mutate, isLoading: submitLoading } = useMutation((params) => {
    return data ? api.updateAppointment(params) : api.addAppointment(params)
  }, {
    onSuccess: (res) => {
      showToast(res.message, !res.status && 'error')
      if (!res.status) {
        setErrors(res.errors)
        return;
      }
      toggle()
      refresh()
    },
    onError: (err) => {
      showToast('Failed to submit appointment', 'error')
    }
  })

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name == 'patient') fetchDoctor();
    });
    return () => subscription.unsubscribe();
  }, [watch])

  useEffect(() => {
    if (data) {
      setValue('id', data.id)
    } else {
      setValue('id', null)
    }
    reset({
      ...data,
      patient: data?.client_id,
      doctor: data?.doctor_id,
      appointment_date: data?.date,
    } ?? {});
  }, [data])

  useEffect(() => {
    if (defaultPatient) {
      setValue('patient', defaultPatient.value)
      setValue('patient', defaultPatient.value)
    }
  }, [defaultPatient])

  const onSubmit = () => mutate(getValues());

  const getAppointmentTime = useMemo(() => {
    if (!getValues("doctor") && !getValues("meeting_type")) {
      return []
    }

    let doctor = doctorData?.find(doctor => doctor.value == getValues("doctor"))

    if (!doctor) {
      return []
    }

    let interval =
      getValues("meeting_type") == "Initial Appointment"
        ? doctor.initial_appointment
        : doctor.followup_appointment

    let dateArr = []
    let start = moment().set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
    let end = moment().set({ hour: 22, minute: 0, second: 0, millisecond: 0 })

    if (data?.date) {
      console.log("ada date")
      console.log(moment(data?.date).format("HH:mm"))
      dateArr.push(moment(data?.date).format("HH:mm"))
    }

    while (start <= end) {
      if (!availability?.includes(moment(start).format("HH:mm"))) {
        dateArr.push(moment(start).format("HH:mm"))
      }
      start = moment(start).add(interval, "minutes")
    }

    console.log(dateArr)
    return dateArr
  }, [data, getValues, doctorData, availability])

  return (
    <Modal size="lg" toggle={() => handleCreateModal()} isOpen={modal} centered>
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? "Update Appointment" : "Create Appointment"}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={e => {
            e.preventDefault()
            onSubmit()
          }}
        >
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-email-Input">Patient</Label>
                <Select
                  options={patientData}
                  onInputChange={e => setSearchPatient(e)}
                  onChange={e => setValue("patient", e.value)}
                  isLoading={loadingPatient}
                  defaultValue={
                    data
                      ? { label: data.client.fullname, value: data.client.id }
                      : defaultPatient
                      ? defaultPatient
                      : null
                  }
                />
                {errors.patient && (
                  <span className="form-text text-danger">
                    {errors.patient[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-password-Input">Doctor</Label>
                <Select
                  options={doctorData}
                  onInputChange={e => setSearchDoctor(e)}
                  onChange={e => setValue("doctor", e.value)}
                  isLoading={loadingDoctor}
                  classNamePrefix="select2-selection"
                  defaultValue={
                    data && {
                      label: data.doctor.fullname,
                      value: data.doctor.id,
                    }
                  }
                />
                {errors.doctor && (
                  <span className="form-text text-danger">
                    {errors.doctor[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-Meeting">Meeting Preferences</Label>
                <select
                  id="formrow-Meeting"
                  className="form-control"
                  defaultValue={data?.meeting_preferences}
                  onChange={e =>
                    setValue("meeting_preferences", e.target.value)
                  }
                >
                  <option value="">Choose...</option>
                  <option value="Zoom">Zoom</option>
                  <option value="Skype">Skype</option>
                  <option value="Telehealth">Telehealth</option>
                  <option value="Microsoft Teams">Microsoft Teams</option>
                  <option value="F2F">F2F</option>
                </select>
                {errors.meeting && (
                  <span className="form-text text-danger">
                    {errors.meeting[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-Meeting">Appointment Type</Label>
                <select
                  id="formrow-Meeting"
                  className="form-control"
                  defaultValue={data?.meeting_type}
                  onChange={e => setValue("meeting_type", e.target.value)}
                >
                  <option value="">Choose...</option>
                  <option value="Initial Appointment">
                    Initial Appointment
                  </option>
                  <option value="Follow-up Appointment">
                    Follow-up Appointment
                  </option>
                  <option value="Custom">Custom</option>
                </select>
                {errors.meeting_type && (
                  <span className="form-text text-danger">
                    {errors.meeting_type[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={3}>
              <div className="mb-3">
                <Label htmlFor="formrow-InputDate">Appointment Date</Label>
                <Input
                  type="date"
                  className="form-control"
                  id="formrow-InputDate"
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                  placeholder="Appointment Date"
                  max="9999-12-31T23:59"
                  defaultValue={data?.date ? formatDateInput(data?.date) : null}
                  onInput={e => {
                    setValue("date", e.target.value)
                  }}
                />
                {errors.date && (
                  <span className="form-text text-danger">
                    {errors.date[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col lg={3}>
              <div className="mb-3">
                <Label htmlFor="formrow-time">Appointment Time</Label>
                <Select
                  options={getAppointmentTime?.map(time => ({
                    label: time,
                    value: time,
                  }))}
                  onChange={e => {
                    setValue(
                      "appointment_date",
                      moment(getValues("date") + " " + e.value).format(
                        "DD-MM-YYYY HH:mm:ss"
                      )
                    )
                    // console.log(moment(getValues('date') + ' ' + e.value).format('DD-MM-YYYY HH:mm:ss'))
                  }}
                  isDisabled={getAppointmentTime.length == 0}
                  isLoading={loadingAvailability}
                  placeholder="Choose Hours"
                  defaultValue={data?.date ? {
                    label: moment(data?.date).format("HH:mm"),
                    value: moment(data?.date).format("HH:mm"),
                  } : null}
                />
                {errors.time && (
                  <span className="form-text text-danger">
                    {errors.time[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <div className="mb-3">
            <Label htmlFor="formrow-Message">Message</Label>
            <input
              type="text"
              className="form-control"
              placeholder="Message"
              id="formrow-Message"
              defaultValue={data?.message ?? ""}
              onInput={e => setValue("message", e.target.value)}
            />
            {errors.message && (
              <span className="form-text text-danger">{errors.message[0]}</span>
            )}
          </div>
        </Form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button
            type="submit"
            className="btn btn-primary w-md"
            disabled={submitLoading}
            onClick={() => onSubmit()}
          >
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

CreateUpdateAppointment.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
  defaultPatient: PropTypes.any,
};

export default CreateUpdateAppointment;