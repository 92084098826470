import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from "prop-types";
import { CardSubtitle, CardTitle, Col, Form, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import { get, post, put } from 'helpers/api_helper';
import { showToast, getRole } from 'helpers/util';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import moment from 'moment';

var elementStyles = {
  base: {
    color: '#000',
    fontWeight: 600,
    fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',

    ':focus': {
      color: '#424770',
    },

    '::placeholder': {
      color: '#ccc',
    },

    ':focus::placeholder': {
      color: '#ccc',
    },
  },
  invalid: {
    color: '#FA755A',
    ':focus': {
      color: '#FA755A',
    },
    '::placeholder': {
      color: '#FFCCA5',
    },
  },
};

var elementClasses = {
  focus: 'focus',
  empty: 'empty',
  invalid: 'invalid',
};

const CardComponent = ({ data, toggle, refresh }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false)
  const [invoice, setInvoice] = useState('')
  const [type, setType] = useState(1)
  const [paymentType, setPaymentType] = useState(1)
  const [notes, setNotes] = useState('')
  const [stripeToken, setStripeToken] = useState(null)
  const [errors, setErrors] = useState([])

  useEffect(() => {
    if (data) {
      setInvoice(data.id)
    }
  }, [data])

  const total = useMemo(() => type == 1 && data.status == 'UNPAID' ? data.total : data.status == 'PARTIALLY' ? data.total - data.paid_amount : data.total / 2, [data, type])

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    let token = '';
    if (paymentType == 1) {
      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardElement);
      const result = await stripe.createToken(cardElement);

      if (result.error) {
        showToast(result.error.message, 'error')
        setLoading(false)
        console.log(result.error.message);
        return;
      }
      setStripeToken(result.token.id)
      token = result.token.id;
    }
    post(`/pay-invoice`, {
      invoice,
      type,
      paymentType,
      stripeToken: token,
      // 0.02 is 2%
      total: total,
      notes,
    }).then(async (res) => {
      showToast(res.message, !res.status && 'error')
      if (!res.status) {
        setErrors(res.errors)
        return;
      }
      refresh()
      setLoading(false)
      toggle()
      const invoice = await get(`/resend-invoice/${res.invoice.id}`);
      showToast(invoice.message, !invoice.status && 'error')
    }).catch(err => {
      setLoading(false)
      showToast('Please check your payment method', 'error')
    });
  };

  return (
    <Form onSubmit={(e) => {
      handleSubmit(e);
    }} id="payment-form">
      <CardTitle>Payment information</CardTitle>
      <CardSubtitle className="mb-4">
        Fill all information below
      </CardSubtitle>
      <div className="d-md-flex justify-content-md-between">
        <div className="font-size-14">
          <b>Invoice ID : #{data.id}</b>
        </div>
        <p className="font-size-14 text-start col-md-5">
          <b>Client : {data.user.fullname}</b>
        </p>
      </div>
      <div className="d-md-flex justify-content-md-between">
        <div className="font-size-14">
          <b>Date : {moment().format("DD MMM YYYY")}</b>
        </div>
        <p className="font-size-14 text-start col-md-5">
          {paymentType == 1 && (
            <>
              <div className="row">
                <b>Subtotal : ${total.toFixed(2)}</b>
              </div>
              <div className="row">
                <b>Merchant Fee : ${(total * 0.02 + 0.3).toFixed(2)}</b>
              </div>
            </>
          )}
          <div className="row">
            <b>Total : ${paymentType == 1 ? (total + (total * 0.02 + 0.3)).toFixed(2) : total.toFixed(2)}</b>
          </div>
        </p>
      </div>
      <div className="d-md-flex justify-content-md-between">
        <b htmlFor="notes">
          Type
        </b>
        {data.status == 'UNPAID' && (
          <b className="col-md-5">
            Payment Type
          </b>
        )}
      </div>
      <div className="d-md-flex justify-content-md-between">
        {data.status == 'UNPAID' && (
          <div>
            <div className="form-check form-check-inline font-size-16">
              <Input
                type="radio"
                value="1"
                id="radioType1"
                defaultChecked
                name="type"
                className="form-check-input"
                onChange={() => setType(1)}
              />
              <Label
                className="form-check-label font-size-13"
                htmlFor="radioType1"
              >
                One Payment
              </Label>
            </div>
            <div className="form-check form-check-inline font-size-16">
              <Input
                type="radio"
                value="2"
                id="radioType2"
                name="type"
                className="form-check-input"
                onChange={() => setType(2)}
              />
              <Label
                className="form-check-label font-size-13"
                htmlFor="radioType2"
              >
                Half Payment
              </Label>
            </div>
          </div>
        )}
        <div className="col-md-5">
          <div className="form-check form-check-inline font-size-16">
            <Input
              type="radio"
              value="1"
              id="radioPaymentType1"
              name="paymentType"
              defaultChecked
              className="form-check-input"
              onChange={() => setPaymentType(1)}
            />
            <Label
              className="form-check-label font-size-13"
              htmlFor="radioPaymentType1"
            >
              <i className="fab fa-cc-mastercard me-1 font-size-20 align-top" />{" "}
              Credit Card
            </Label>
          </div>
          {getRole() != 'Patient' && (
            <div className="form-check form-check-inline font-size-16">
              <Input
                type="radio"
                value="2"
                id="radioPaymentType2"
                name="paymentType"
                className="form-check-input"
                onChange={() => setPaymentType(2)}
              />
              <Label
                className="form-check-label font-size-13"
                htmlFor="radioPaymentType2"
              >
                <i className="far fa-money-bill-alt me-1 font-size-20 align-top" />{" "}
                Counter
              </Label>
            </div>
          )}
        </div>
      </div>

      {data.status == 'PARTIALLY' && (
        <b className="text-warning font-size-16">SECOND PAYMENT</b>
      )}

      {paymentType == 1 && (
        <>
          <h5 className="mb-3 font-size-15">
            For card Payment
          </h5>
          <CardElement
          className="form-control p-2"
            options={{
              hidePostalCode: true,
              style: elementStyles,
              classes: elementClasses
            }}
          />
        </>
      )}

      <Row>
        <FormGroup className="mb-0">
          <Label htmlFor="notes">
            Notes
          </Label>
          <Input
            type="text"
            className="form-control"
            id="notes"
            placeholder="Enter Notes"
            onChange={(e) => setNotes(e.target.value)}
          />
        </FormGroup>
      </Row>

      <button type="submit" className="btn btn-primary w-md float-end mt-5" disabled={loading}>
        {loading ? (
          <>
            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
            Loading
          </>
        ) : (
          <span>Submit</span>
        )}
      </button>
    </Form>
  )
};

CardComponent.propTypes = {
  data: PropTypes.object,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
};

export default CardComponent