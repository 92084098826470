import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import Select from "react-select";
import { Col, Form, Input, Label, Modal, Row } from 'reactstrap';
import { formatDateTimeInput, showToast } from 'helpers/util';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import { useForm } from 'react-hook-form';

const FlagClientModal = ({ data, toggle, modal, handleCreateModal, refetch }) => {
  const [errors, setErrors] = useState([])

  const { getValues, setValue, handleSubmit, reset, formState: { formErrors } } = useForm({
    defaultValues: {
    }
  });

  const { mutate, isLoading: submitLoading } = useMutation((params) => api.flagPatient(params, params.id), {
    onSuccess: (res) => {
      if (res.status) {
        showToast(res.message, !res.status && 'error')
        refetch()
        // !data.id && setSchedules(prev => [...prev, {...res?.event, start: formatDateTimeInput(res?.event.start)}])
        toggle()
      } else {
        setErrors(res.errors)
      }
    },
    onError: (err) => {
      console.log(err)
      showToast('Failed to submit', 'error')
    }
  });

  const { mutate: deleteEvent, isLoading: deleteLoading } = useMutation(() => api.deleteEvent(data?.id), {
    onSuccess: (res) => {
      if (res.status) {
        showToast(res.message, !res.status && 'error')
        refetch()
        // !data.id && setSchedules(prev => [...prev, {...res?.event, start: formatDateTimeInput(res?.event.start)}])
        toggle()
      } else {
        setErrors(res.errors)
      }
    },
    onError: (err) => {
      console.log(err)
      showToast('Failed to submit', 'error')
    }
  });

  const onSubmit = () => {
    mutate({
      id: data?.user.id,
      flag_message: getValues('flag_message'),
    })
  };

  return (
    <Modal
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          Flag Client
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}>
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label htmlFor="formrow-InputDate">Message</Label>
                <textarea
                  className="form-control"
                  onInput={(e) => setValue('flag_message', e.target.value)}
                  placeholder="Message"
                  rows={5}
                >
                </textarea>
                {errors.message && <span className="form-text text-danger">{errors.message[0]}</span>}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="modal-footer d-md-flex justify-content-md-between">
        <div className="d-md-flex justify-content-md-end">
          <button type="submit" className="btn btn-primary w-md" disabled={submitLoading} onClick={(e) => {
            e.preventDefault()
            onSubmit()
          }}>
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

FlagClientModal.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  handleCreateModal: PropTypes.func,
  type: PropTypes.string,
  userId: PropTypes.number,
  setSchedules: PropTypes.func,
  refetch: PropTypes.func,
};

export default FlagClientModal;