import React, { useState } from "react"
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import {
  Col,
  Input,
  Label,
  Row,
} from "reactstrap"

const Password = ({data, refetch, errors}) => {
  const { setValue } = useFormContext();
  return (
    <Row>
      <Col md={6}>
        <div className="mb-3">
          <Label htmlFor="formrow-password-Input">Password</Label>
          <Input
            type="password"
            className="form-control"
            id="formrow-password-Input"
            placeholder="Password"
            onInput={(e) => setValue('password', e.target.value)}
          />
          {errors?.password && <span className="form-text text-danger">{errors?.password[0]}</span>}
        </div>
      </Col>
      <Col md={6}>
        <div className="mb-3">
          <Label htmlFor="formrow-password_confirmation-Input">Password Confirmation</Label>
          <Input
            type="password"
            className="form-control"
            id="formrow-password_confirmation-Input"
            placeholder="Password Confirmation"
            onInput={(e) => setValue('password_confirmation', e.target.value)}
          />
          {errors?.password_confirmation && <span className="form-text text-danger">{errors?.password_confirmation[0]}</span>}
        </div>
      </Col>
    </Row>
  )
}

Password.propTypes = {
  data: PropTypes.object,
  refetch: PropTypes.func,
  errors: PropTypes.object,
};

export default React.memo(Password);