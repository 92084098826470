import { del, get, post } from 'helpers/api_helper';
import PropTypes from 'prop-types'
import { formatDate, getRole } from 'helpers/util';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Pagination } from 'react-laravel-paginex';
import { Link } from 'react-router-dom';
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Label, Row, Spinner, UncontrolledButtonDropdown } from 'reactstrap';
import Confirmation from 'components/Alert/Confirmation';
import DeleteConfirmation from 'components/Alert/DeleteConfirmation';
import { useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import AcceptPatient from '../AcceptPatient';
import ShowPatientModal from '../show';

const DataPatientNew = () => {
  const [id, setId] = useState('')
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(20)
  const [page, setPage] = useState(1)
  const [showPatient, setShowPatient] = useState(false)
  const [showAccept, setShowAccept] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showDecline, setShowDecline] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const { data, refetch: getData, isLoading } = useQuery(['new-patient', search, page, limit], () => api.getNewPatient({
    params: {
      page: page,
      search: search,
      limit: limit,
    }
  }), {
    refetchOnWindowFocus: false,
  });

  const deleteAction = async () => {
    const patient = await del(`/patient/${id}`);
    setShowDelete(false)
    getData();
  }

  const acceptPatient = async (accept = true, doctors) => {
    const patient = await post(`/accept-patient/${id}`, {
      accept,
      doctors
    });
    setShowAccept(false)
    getData();
  }

  const handlePatientModal = () => {
    if (showPatient) {
      setShowPatient(false);
    } else {
      setShowPatient(true);
    }
  };

  const selectRow = {
    mode: "checkbox",
    hideSelectColumn: true,
    hideSelectAll: true,
  };

  //pagination customization
  const pageOptions = {
    page: 1,
    sizePerPage: limit,
    pageStartIndex: 1,
    totalSize: data?.total, // replace later with size(patients),
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "id",
      patient: "desc",
      order: 'desc',
    },
  ];

  const EcommercePatientColumns = toggleModal => [
    {
      dataField: "user.fullname",
      text: "Patient",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        // <Link to={`/patients/${row.user.id}`}>{row.user.fullname}</Link>
        <a href="javascript:void()" onClick={() => {
          setId(row.user.id)
          handlePatientModal()
        }}>{row.user.fullname}</a>
      ),
    },
    {
      dataField: "type_patient.name_type",
      text: "Patient Type",
      sort: true,
    },
    {
      dataField: "user.phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Created",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => formatDate(row.created_at),
    },
    {
      dataField: "user.requirement.referral_letter",
      text: "Referral",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, i) => (
        <Link to={`patients/${row.user.id}?tabs=4`} href={row.user.requirement?.referral_letter ? row.user.requirement?.referral_letter[0] : '#'}>
          <Badge
            className={`font-size-12 badge-soft-info`}
            color={`font-size-12 badge-soft-${row.user.requirement && 'info'}`}
            pill
          >
            {row.user.requirement ? 'View Referral' : '-'}
          </Badge>
        </Link>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      hidden: getRole() == 'Staff' ? true : false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <UncontrolledButtonDropdown direction="start">
            <DropdownToggle caret>
              Action
            </DropdownToggle>
            <DropdownMenu>
              {(getRole() == 'Admin' || getRole() == 'Staff') && (
                <>
                  <DropdownItem onClick={() => {
                    setId(row.user.id);
                    setShowAccept(true)
                  }}>
                    Accept
                  </DropdownItem>
                  {/* <DropdownItem>
                    Edit
                  </DropdownItem> */}
                </>
              )}
              {(getRole() == 'Admin' || getRole() == 'Staff') && (
                <DropdownItem onClick={() => {
                  setId(row.user.id);
                  setShowDecline(true)
                }}>
                  Decline
                </DropdownItem>
              )}
              {getRole() == 'Admin' && (
                <DropdownItem onClick={() => {
                  setId(row.id);
                  setShowDelete(true);
                }}>
                  Delete
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Row>
        <Col sm="3">
          <div className="row mb-4">
            <Label
              htmlFor="horizontal-firstname-Input"
              className="col-sm-4 col-form-label me-n5"
            >
              Show
            </Label>
            <Col sm={3}>
              <select
                id="formrow-limit"
                className="form-control"
                onChange={(e) => setLimit(e.target.value)}
                defaultValue={limit}
              >
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </Col>
            <Label className="col-sm-4 col-form-label ms-n3">
              entries
            </Label>
          </div>
        </Col>
        <Col sm="9">
          <div className="text-sm-end">
            <div className="search-box me-2 mb-2 d-inline-block">
              <div className="position-relative">
                <input
                  placeholder="Search..."
                  className="form-control"
                  onInput={(e) => {
                    setSearch(e.target.value);
                  }}
                  value={search}
                  type="text"
                />
                <i className="bx bx-search-alt search-icon" />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {isLoading ? (
          <Spinner className="ms-2 spinner-loading" color="success" />
      ) : (
        <Row>
          <Col sm="12">
              {data && (
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={EcommercePatientColumns()}
                  data={data.data}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={data.data}
                      columns={EcommercePatientColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField="id"
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="align-items-md-center mt-30">
                            <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                              <Pagination options={{ numberButtonClass: "page-item d-flex" }}changePage={(e) => setPage(e.page)} data={data} />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              )}
          </Col>
        </Row>
      )}
      <Confirmation showConfirm={showConfirm} setShowConfirm={() => setShowConfirm(false)} action={acceptPatient} accept={true} />
      <Confirmation showConfirm={showDecline} setShowConfirm={() => setShowDecline(false)} action={acceptPatient} accept={false} />
      <DeleteConfirmation showDelete={showDelete} setShowDelete={() => setShowDelete(false)} deleteAction={deleteAction} />
      <AcceptPatient showAccept={showAccept} setShowAccept={() => setShowAccept(false)} action={acceptPatient} />
      <ShowPatientModal modal={showPatient} toggle={() => setShowPatient(false)} handleCreateModal={handlePatientModal} id={id} />
    </React.Fragment>
  )
}

export default DataPatientNew