import React, { useEffect, useState } from "react"
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import {
  Col,
  Input,
  Label,
  Row,
} from "reactstrap"

const GeneralSetting = ({ data, refetch, errors }) => {
  const { setValue } = useFormContext();

  return (
    <>
      <Row>
        <Col md={4}>
          <div className="mb-3">
            <Label>Clinic Email</Label>
            <Input
              type="email"
              className="form-control"
              placeholder="Clinic Email"
              onInput={(e) => setValue('clinic_email', e.target.value)}
              defaultValue={data?.clinic_email}
            />
            {errors?.clinic_email && <span className="form-text text-danger">{errors?.clinic_email[0]}</span>}
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-3">
            <Label>Clinic Phone</Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Clinic Phone"
              onInput={(e) => setValue('clinic_phone', e.target.value)}
              defaultValue={data?.clinic_phone}
            />
            {errors?.clinic_phone && <span className="form-text text-danger">{errors?.clinic_phone[0]}</span>}
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-3">
            <Label>Clinic Address</Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Clinic Address"
              onInput={(e) => setValue('clinic_address', e.target.value)}
              defaultValue={data?.clinic_address}
            />
            {errors?.clinic_address && <span className="form-text text-danger">{errors?.clinic_address[0]}</span>}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Label>Stripe Private Key</Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Stripe Private Key"
              onInput={(e) => setValue('stripe_pk', e.target.value)}
              defaultValue={data?.stripe_pk}
            />
            {errors?.stripe_pk && <span className="form-text text-danger">{errors?.stripe_pk[0]}</span>}
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label>Stripe Secret Key</Label>
            <Input
              type="password"
              className="form-control"
              placeholder="Stripe Secret Key"
              onInput={(e) => setValue('stripe_sk', e.target.value)}
              defaultValue={data?.stripe_sk}
            />
            {errors?.stripe_sk && <span className="form-text text-danger">{errors?.stripe_sk[0]}</span>}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Label>Logo</Label>
            <Input
              type="file"
              className="form-control"
              placeholder="Logo"
              onChange={(e) => setValue('logo', e.target.files[0])}
            />
            {errors?.logo && <span className="form-text text-danger">{errors?.logo[0]}</span>}
          </div>
        </Col>
        {/* <Col md={6}>
          <div className="mb-3">
            <Label>Manual</Label>
            <Input
              type="password"
              className="form-control"
              placeholder="Manual"
              onInput={(e) => setValue('manual', e.target.value)}
              defaultValue={data?.manual}
            />
            {errors?.manual && <span className="form-text text-danger">{errors?.manual[0]}</span>}
          </div>
        </Col> */}
      </Row>
    </>
  )
}

GeneralSetting.propTypes = {
  data: PropTypes.object,
  refetch: PropTypes.func,
  errors: PropTypes.object,
};

export default React.memo(GeneralSetting);