import PropTypes from "prop-types";
import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import { getRole } from "helpers/util";
import AppointmentFinish from "./Tabs/AppointmentFinish";
import AppointmentTab from "./Tabs/AppointmentTab";
import AppointmentToday from "./Tabs/AppointmentToday";

const Appointment = () => {
  const [activeTab, setActiveTab] = useState('1')

  const handleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>ADHD Centre</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Appointment" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {(getRole() == 'Admin' || getRole() == 'Staff' || getRole() == 'Doctor') && (
                    <>
                      <Nav className="icon-tab nav-justified">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={
                              activeTab === "1" ? 'active' : null
                            }
                            onClick={() => {
                              handleTab("1");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              <i className="fas fa-home"></i> Upcoming Appointment
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="fas fa-home"></i>
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={
                              activeTab === "2" ? 'active' : null
                            }
                            onClick={() => {
                              handleTab("2");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              <i className='bx bx-skip-next-circle'></i> Today Appointment
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="fas fa-home"></i>
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={
                              activeTab === "3" ? 'active' : null
                            }
                            onClick={() => {
                              handleTab("3");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              <i className="fas fa-user"></i> Finish Appointment
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="fas fa-user"></i>
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        activeTab={activeTab}
                        className="p-3 text-muted"
                      >
                        <TabPane tabId="1">
                          {activeTab == 1 && (
                            <AppointmentTab />
                          )}
                        </TabPane>
                        <TabPane tabId="2">
                          {activeTab == 2 && (
                            <AppointmentToday />
                          )}
                        </TabPane>
                        <TabPane tabId="3">
                          {activeTab == 3 && (
                            <AppointmentFinish />
                          )}
                        </TabPane>
                      </TabContent>
                    </>
                  )}
                  {getRole() == 'Patient' && (
                    <AppointmentTab />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Appointment.propTypes = {
  appointments: PropTypes.array,
  onGetAppointments: PropTypes.func,
  onAddNewAppointment: PropTypes.func,
  onDeleteAppointment: PropTypes.func,
  onUpdateAppointment: PropTypes.func,
};

export default withRouter(Appointment);

