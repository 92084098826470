import PropTypes from 'prop-types';
import React, { useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';

const Confirmation = ({ showConfirm, setShowConfirm, action, accept, title = 'Are you sure?', actionReject = null }) => {
  const [success, setSuccess] = useState(false)

  return (
    <>
      {success && (
        <SweetAlert
          success
          timeout={1500}
          title="Processed"
          onConfirm={() => {
            setSuccess(false)
          }}
        >
          Your request has been processed.
        </SweetAlert>
      )}

      {showConfirm && (
        <SweetAlert
          title={title}
          warning
          showCancel
          confirmButtonText="Yes, i'm very sure!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setShowConfirm(false)
            setSuccess(true)
            action(accept)
          }}
          onCancel={() => {
            setShowConfirm(false)
            actionReject ? actionReject() : null;
          }}
        >
          You won&apos;t be able to revert this!
        </SweetAlert>
      )}
    </>
  );
}

Confirmation.propTypes = {
  showConfirm: PropTypes.bool,
  setShowConfirm: PropTypes.func,
  action: PropTypes.func,
  accept: PropTypes.bool,
  title: PropTypes.string,
  actionReject: PropTypes.func,
};

export default React.memo(Confirmation)