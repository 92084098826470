import React, { useState } from "react"
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import {
  Col,
  Input,
  Label,
  Row,
} from "reactstrap"

const EmergencyContact = ({data, refetch, errors, disableInput = false}) => {
  const { setValue } = useFormContext();
  // const [errors, setErrors] = useState([])
  return (
    <>
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-first-name-Input">First Name</Label>
            <Input
              type="text"
              className="form-control"
              id="formrow-first-name-Input"
              placeholder="First Name"
              defaultValue={data?.user.emergency_contact?.first_name}
              onInput={(e) => setValue('emergency_name', e.target.value)}
              disabled={disableInput}
            />
            {errors?.emergency_name && <span className="form-text text-danger">{errors?.emergency_name[0]}</span>}
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-last-name-Input">Last Name</Label>
            <Input
              type="text"
              className="form-control"
              id="formrow-last-name-Input"
              placeholder="Last Name"
              defaultValue={data?.user.emergency_contact?.last_name}
              onInput={(e) => setValue('emergency_last_name', e.target.value)}
              disabled={disableInput}
            />
            {errors?.emergency_last_name && <span className="form-text text-danger">{errors?.emergency_last_name[0]}</span>}
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <div className="mb-3">
            <Label htmlFor="formrow-emergency-phone-Input">Phone</Label>
            <Input
              type="text"
              className="form-control"
              id="formrow-emergency-phone-Input"
              placeholder="Phone"
              defaultValue={data?.user.emergency_contact?.phone}
              onInput={(e) => setValue('emergency_phone', e.target.value)}
              disabled={disableInput}
            />
            {errors?.emergency_phone && <span className="form-text text-danger">{errors?.emergency_phone[0]}</span>}
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-3">
            <Label htmlFor="formrow-emergency-email-Input">Email</Label>
            <Input
              type="text"
              className="form-control"
              id="formrow-emergency-email-Input"
              placeholder="Email"
              defaultValue={data?.user.emergency_contact?.email}
              onInput={(e) => setValue('emergency_email', e.target.value)}
              disabled={disableInput}
            />
            {errors?.emergency_email && <span className="form-text text-danger">{errors?.emergency_email[0]}</span>}
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-3">
            <Label htmlFor="formrow-Relationship-Input">Relationship</Label>
            <Input
              type="text"
              className="form-control"
              id="formrow-Relationship-Input"
              placeholder="Relationship"
              defaultValue={data?.user.emergency_contact?.relationship}
              onInput={(e) => setValue('emergency_relationship', e.target.value)}
              disabled={disableInput}
            />
            {errors?.emergency_relationship && <span className="form-text text-danger">{errors?.emergency_relationship[0]}</span>}
          </div>
        </Col>
      </Row>
    </>
  )
}

EmergencyContact.propTypes = {
  data: PropTypes.object,
  refetch: PropTypes.func,
  errors: PropTypes.object,
  disableInput: PropTypes.bool,
};

export default React.memo(EmergencyContact);