import { useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import { download } from 'helpers/api_helper';
import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { MetaTags } from 'react-meta-tags'
import { Button, Card, CardBody, Col, Container, Row, Spinner } from 'reactstrap'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import { currency } from 'helpers/util';

const ReportDoctor = () => {
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)
  const [doctor, setDoctor] = useState(null)
  const [status, setStatus] = useState(null)
  const [series, setSeries] = useState([{
    data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
  },])
  const [options, setOptions] = useState({
    labels: ["Working Days", "Sick Leave", 'Day Off', 'Annual Leave'],
    chart: {
      foreColor: '#fff',
    },
    theme: {
      mode: 'dark',
      monochrome: {
        enabled: true,
        color: '#5653FE',
        shadeTo: 'dark',
        shadeIntensity: 0.65
      },
    },
    title: {
      text: 'Doctor Report',
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      }
    },
    dataLabels: {
      offsetX: 20,
      formatter: function (val, opts) {
        return currency(val)
      },
    },
    tooltip: {
      y: {
        show: true,
        formatter: function (val, opts) {
          return currency(val)
        },
      },
    }
  })

  const { data: doctors, isLoading: doctorLoading } = useQuery(['get-doctor'], () => api.getSelectDoctor(), {
    refetchOnWindowFocus: false
  });

  const { data, refetch, isLoading } = useQuery(['get-report-doctor', [from, to]], () => api.getReportDoctor({
    params: {
      from,
      to,
    }
  }), {
    onSuccess: (res) => {
      const labels = res.data.map(item => item.doctor?.fullname);
      const seriesClinicFeeData = res.data.map(item => item.paid_amount * (100 - item.admin_fee) / 100);
      const seriesDoctorFeeData = res.data.map(item => item.paid_amount * item.admin_fee / 100);
      setSeries([
        {
          name: 'Doctor Fee',
          data: seriesClinicFeeData,
        },
        {
          name: 'Clinic Fee',
          data: seriesDoctorFeeData,
        }
      ])
      setOptions(prev => { return {...prev, labels} })
    },
    enabled: !!(from && to)
  });

  const handleDownload = async () => {
    setLoadingDownload(true)
    await download('report-doctor', 'Doctor Report - ADHD Centre.pdf', {
      responseType: 'blob',
      params: {
        from,
        to,
        doctor,
        status,
      }
    })
    setLoadingDownload(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>ADHD Centre</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Report Doctor" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody style={{minHeight: 500}}>
                  <div className="d=flex justify-content-between mb-3 align-items-center">
                    <Row className="mb-3">
                      <Col md="2">
                        <label>From</label>
                        <input type="date" className="form-control" onChange={e => setFrom(e.target.valueAsDate)} />
                      </Col>
                      <Col md="2">
                        <label>To</label>
                        <input type="date" className="form-control" onChange={e => setTo(e.target.valueAsDate)} />
                      </Col>
                      <Col md="2">
                        <label>Doctor</label>
                        <Select
                          maxMenuHeight={200}
                          options={doctors}
                          autoComplete="off"
                          onChange={(e) => setDoctor(e.value)}
                          isLoading={doctorLoading}
                          classNamePrefix="select2-selection"
                        />
                      </Col>
                      <Col md="2">
                        <label>Appointment Status</label>
                        <select className="form-control" onChange={(e) => setStatus(e.target.value)}>
                          <option value="">Select...</option>
                          <option value="PENDING">PENDING</option>
                          <option value="FINISH">FINISH</option>
                        </select>
                      </Col>
                      <Col className="align-self-center text-end">
                        <Button color="primary" onClick={handleDownload} disabled={!from || !to || !doctor || loadingDownload}>
                          {loadingDownload ? (
                            <>
                              <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                              Loading
                            </>
                          ) : (
                              <span>Download Report</span>
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  {isLoading && from && to ? (
                    <Spinner className="ms-2 spinner-loading" color="success" />
                  ) : (
                    !isLoading && from && to ? (
                      <Row>
                        <Col>
                          <div id="doctor-chart">
                            <ReactApexChart
                              series={series}
                              options={options}
                              type="bar"
                              height={800}
                              className="apex-charts"
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : null
                  )}
                  <small>* Based on payment received</small>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default React.memo(ReportDoctor)