import PropTypes from 'prop-types';
import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';

const PaymentReminder = ({ showConfirm, setShowConfirm, title = 'Are you sure?', message }) => {
  return (
    <>
      {showConfirm && (
        <SweetAlert
          title={title}
          confirmButtonText="OK!"
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setShowConfirm(false)
          }}
        >
          {message}
        </SweetAlert>
      )}
    </>
  );
}

PaymentReminder.propTypes = {
  showConfirm: PropTypes.bool,
  setShowConfirm: PropTypes.func,
  action: PropTypes.func,
  accept: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  actionReject: PropTypes.func,
};

export default React.memo(PaymentReminder)