import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from "prop-types";
import Select from "react-select";
import { Col, Form, Input, Label, Modal, Row } from 'reactstrap';
import { get, post, put } from 'helpers/api_helper';
import { debounce } from 'lodash';
import { formatDateTimeInput, showToast } from 'helpers/util';

const CreateUpdateProduct = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [loading, setLoading] = useState(false)
  const [sku, setSku] = useState('')
  const [name, setName] = useState('')
  const [price, setPrice] = useState('')
  const [taxType, setTaxType] = useState('Inclusive')
  const [errors, setErrors] = useState([])

  useEffect(() => {
    if (data) {
      setSku(data.sku)
      setName(data.name)
      setPrice(data.price)
      setTaxType(data.tax_type)
    }else{
      setSku('')
      setName('')
      setPrice('')
      setTaxType('Inclusive')
    }
  }, [data])

  const handleSubmit = async() => {
    setLoading(true);
    if (data) {
      var res = await put(`products/${data.id}`, {
        sku,
        name,
        price,
        taxType,
      });
    }else{
      var res = await post('products', {
        sku,
        name,
        price,
        taxType,
      });
    }
    showToast(res.message, !res.status && 'error')
    setLoading(false)
    if (!res.status) {
      setErrors(res.errors)
      return;
    }
    toggle()
    refresh()
  }

  return (
    <Modal
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? 'Update Product' : 'Create Product'}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
          }}>
          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-sku">Product SKU</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-sku"
                  placeholder="Product SKU"
                  value={sku}
                  onInput={(e) => setSku(e.target.value)}
                />
                {errors.sku && <span className="form-text text-danger">{errors.sku[0]}</span>}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-name">Product Name</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-name"
                  placeholder="Product Name"
                  value={name}
                  onInput={(e) => setName(e.target.value)}
                />
                {errors.name && <span className="form-text text-danger">{errors.name[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-price">Price</Label>
                <Input
                  type="number"
                  className="form-control"
                  id="formrow-price"
                  placeholder="Product Price"
                  value={price}
                  onInput={(e) => setPrice(e.target.value)}
                />
                {errors.price && <span className="form-text text-danger">{errors.price[0]}</span>}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-taxType">Product Tax Type</Label>
                <select
                  id="formrow-taxType"
                  className="form-control"
                  value={taxType}
                  onChange={(e) => setTaxType(e.target.value)}
                >
                  <option value="Inclusive">Inclusive</option>
                  <option value="Exclusive">Exclusive</option>
                </select>
                {errors.taxType && <span className="form-text text-danger">{errors.taxType[0]}</span>}
              </div>
            </Col>
          </Row>

          <div className="d-md-flex justify-content-md-end">
            <button type="submit" className="btn btn-primary w-md" disabled={loading}>
              {loading ? (
                <>
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                  Loading
                </>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

CreateUpdateProduct.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
};

export default CreateUpdateProduct;