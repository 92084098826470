import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { Col, Form, Input, Label, Modal, Row } from 'reactstrap';
import { post, put } from 'helpers/api_helper';
import { getRole, showToast } from 'helpers/util';

const ResetPassword = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState(null)
  const [password_confirmation, setPasswordConfirmation] = useState(null)
  const [errors, setErrors] = useState([])

  const handleSubmit = async () => {
    setLoading(true);
    var res = await post(`reset-password/${data.id}`, {
      password,
      password_confirmation,
    });
    showToast(res.message, !res.status && 'error')
    setLoading(false)
    if (!res.status) {
      setErrors(res.errors)
      return;
    }
    toggle()
    refresh()
  }

  return (
    <Modal
      size="lg"
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          Reset Password
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-password-Input">Password</Label>
                <Input
                  type="password"
                  className="form-control"
                  id="formrow-password-Input"
                  placeholder="Password"
                  onInput={(e) => setPassword(e.target.value)}
                />
                {errors.password && <span className="form-text text-danger">{errors.password[0]}</span>}
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-password_confirmation-Input">Password Confirmation</Label>
                <Input
                  type="password"
                  className="form-control"
                  id="formrow-password_confirmation-Input"
                  placeholder="Password Confirmation"
                  onInput={(e) => setPasswordConfirmation(e.target.value)}
                />
                {errors.password_confirmation && <span className="form-text text-danger">{errors.password_confirmation[0]}</span>}
              </div>
            </Col>
          </Row>

          <div className="d-md-flex justify-content-md-end">
            <button type="submit" className="btn btn-primary w-md" disabled={loading}>
              {loading ? (
                <>
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                  Loading
                </>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

ResetPassword.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
};

export default React.memo(ResetPassword);