import React, { useState } from "react"
import {
  Card,
  CardBody,
} from "reactstrap"
import PropTypes from "prop-types";

const MenuLeftBar = ({active, setActive}) => {
  return (
    <React.Fragment>
      <Card className="filemanager-sidebar me-md-2">
        <CardBody>
          <div className="d-flex flex-column h-100">
            <div className="mb-4">
              <ul className="list-unstyled categories-list">
                <li>
                  <a className={`${active == 1 ? 'text-primary' : 'text-body'} d-flex align-items-center`} onClick={() => setActive(1)}>
                    <i className="mdi mdi-clipboard-flow-outline font-size-16 text-muted me-2"></i>{" "}
                    <span className="me-auto">Current Rx</span>
                  </a>
                </li>
                <li>
                  <a className={`${active == 2 ? 'text-primary' : 'text-body'} d-flex align-items-center`} onClick={() => setActive(2)}>
                    <i className="mdi mdi-database-plus-outline font-size-16 me-2"></i>{" "}
                    <span className="me-auto">Correspondence In</span>
                  </a>
                </li>
                <li>
                  <a className={`${active == 3 ? 'text-primary' : 'text-body'} d-flex align-items-center`} onClick={() => setActive(3)}>
                    <i className="mdi mdi-database-minus-outline font-size-16 me-2"></i>{" "}
                    <span className="me-auto">Correspondence Out</span>{" "}
                  </a>
                </li>
                <li>
                  <a className={`${active == 4 ? 'text-primary' : 'text-body'} d-flex align-items-center`} onClick={() => setActive(4)}>
                    <i className="mdi mdi-folder-text text-muted font-size-16 me-2"></i>{" "}
                    <span className="me-auto">Past Prescriptions</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

MenuLeftBar.propTypes = {
  active: PropTypes.func,
  setActive: PropTypes.func,
};

export default React.memo(MenuLeftBar)
