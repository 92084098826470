import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { useParams } from "react-router-dom"
import {
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import classnames from "classnames";
import Profile from "./components/Profile";
import Password from "./components/Password";
import api from "helpers/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import EmergencyContact from "./components/EmergencyContact";
import Doctor from "./components/Doctor";
import Document from "./components/Document";
import PersonalDetail from "./components/PersonalDetail";
import { getRole, showToast } from "helpers/util";

const DetailPatient = () => {
  const params = useParams()
  const search = window.location.search;
  const query = new URLSearchParams(search);
  const [errors, setErrors] = useState([])
  const [customActiveTab, setcustomActiveTab] = useState(query.get('tabs') ?? "1");
  const methods = useForm();
  const { data, refetch, isLoading } = useQuery(['patient-detail'], () => api.getPatientDetail(params.id), {
    refetchOnWindowFocus: false
  });

  const { mutate, isLoading: submitLoading } = useMutation((params) => {
    return api.updatePatientDetail(params)
  }, {
    onSuccess: (res) => {
      showToast(res.message, !res.status && 'error')
      if (!res.status) {
        showToast(Object.values(res?.errors)[0], 'error');
        setErrors(res.errors)
        return;
      }
      refetch()
    },
    onError: (err) => {
      showToast('Failed to save changes', 'error')
    }
  })

  const onSubmit = () => mutate(methods.getValues());

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  useEffect(() => {
    setErrors([]);
    methods.reset({
      ...data,
      ...data?.user,
      emergency_name: data?.user.emergency_contact?.first_name,
      emergency_last_name: data?.user.emergency_contact?.last_name,
      emergency_email: data?.user.emergency_contact?.email,
      emergency_phone: data?.user.emergency_contact?.phone,
      emergency_relationship: data?.user.emergency_contact?.relationship,
      patient_type: data?.type_patients_id,
      patient_type_problem: data?.type_presenting_problem_id,
      doctor: data?.doctors.map(val => val.id),
    } ?? {});
  }, [data])

  return (
    <React.Fragment>
      <div className="page-content" >
        <MetaTags>
          <title>ADHD Centre</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contacts" breadcrumbItem="Profile Patient" />
          {isLoading ? (
            <Spinner className="ms-2 spinner-loading" color="success" />
          ) : (
            <>
              <Card style={{minHeight: 500}}>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">Main Profile</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">Personal Detail</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleCustom("3");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">Emergency Contact</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "4",
                        })}
                        onClick={() => {
                          toggleCustom("4");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-envelope"></i>
                        </span>
                        <span className="d-none d-sm-block">Document</span>
                      </NavLink>
                    </NavItem>
                    {getRole() != 'Patient' && getRole() != 'Doctor' && (
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "5",
                          })}
                          onClick={() => {
                            toggleCustom("5");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-cog"></i>
                          </span>
                          <span className="d-none d-sm-block">Assign Doctor</span>
                        </NavLink>
                      </NavItem>
                    )}
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "6",
                        })}
                        onClick={() => {
                          toggleCustom("6");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-cog"></i>
                        </span>
                        <span className="d-none d-sm-block">Change Password</span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    <FormProvider {...methods} autoComplete="off">
                      <TabPane tabId="1">
                        <Profile data={data} refetch={refetch} errors={errors} />
                      </TabPane>
                      <TabPane tabId="2">
                        <PersonalDetail data={data} refetch={refetch} errors={errors} />
                      </TabPane>
                      <TabPane tabId="3">
                        <EmergencyContact data={data} refetch={refetch} errors={errors} />
                      </TabPane>
                      <TabPane tabId="4">
                        <Document data={data} refetch={refetch} errors={errors} />
                      </TabPane>
                      <TabPane tabId="5">
                        <Doctor data={data} refetch={refetch} errors={errors} />
                      </TabPane>
                      <TabPane tabId="6">
                        <Password data={data} refetch={refetch} errors={errors} />
                      </TabPane>
                      <div className="d-md-flex justify-content-md-end">
                        <button type="submit" className="btn btn-primary w-md" disabled={submitLoading} onClick={() => methods.handleSubmit(onSubmit())}>
                          {submitLoading ? (
                            <>
                              <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                              Loading
                            </>
                          ) : (
                            <span>Save Changes</span>
                          )}
                        </button>
                      </div>
                    </FormProvider>
                  </TabContent>
                </CardBody>
              </Card>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default React.memo(DetailPatient);