import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import Select from "react-select";
import { Col, Form, Input, Label, Modal, Row } from 'reactstrap';
import { formatDateTimeInput, showToast } from 'helpers/util';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import { useForm } from 'react-hook-form';

const CreateUpdateEvent = ({ data, toggle, modal, handleCreateModal, modalDate, userId, setSchedules, refetch }) => {
  const [errors, setErrors] = useState([])
  const [allDay, setAllDay] = useState(false)

  const { getValues, setValue, handleSubmit, reset, formState: { formErrors } } = useForm({
    defaultValues: {
      id: data ? data?.id : null,
      date: data ? data?.start : modalDate,
      end: data ? data?.end : modalDate,
    }
  });

  const { mutate, isLoading: submitLoading } = useMutation((params) => data?.id ? api.updateEvent(params) : api.addNewEvent(params), {
    onSuccess: (res) => {
      if (res.status) {
        showToast(res.message, !res.status && 'error')
        refetch()
        // !data.id && setSchedules(prev => [...prev, {...res?.event, start: formatDateTimeInput(res?.event.start)}])
        toggle()
      }else{
        setErrors(res.errors)
      }
    },
    onError: (err) => {
      console.log(err)
      showToast('Failed to submit event', 'error')
    }
  });

  const { mutate: deleteEvent, isLoading: deleteLoading } = useMutation(() => api.deleteEvent(data?.id), {
    onSuccess: (res) => {
      if (res.status) {
        showToast(res.message, !res.status && 'error')
        refetch()
        // !data.id && setSchedules(prev => [...prev, {...res?.event, start: formatDateTimeInput(res?.event.start)}])
        toggle()
      }else{
        setErrors(res.errors)
      }
    },
    onError: (err) => {
      console.log(err)
      showToast('Failed to submit event', 'error')
    }
  });

  const onSubmit = () => {
    mutate({
      id: data?.id,
      appointment_id: null,
      user_id: userId,
      title: getValues('title'),
      start: getValues('date'),
      end: getValues('end'),
      all_day: allDay,
      class: getValues('class'),
    })
  };

  useEffect(() => {
    if (data?.id) {
      setValue('id', data.id)
      setValue('title', data.title)
      setValue('date', formatDateTimeInput(data.start))
      setValue('end', formatDateTimeInput(data.end))
      setValue('all_day', data.all_day.toString())
    } else {
      setValue('id', null)
      setValue('date', data ? formatDateTimeInput(data?.start) : formatDateTimeInput(modalDate))
    }
    reset({
      ...data,
      date: data ? formatDateTimeInput(data?.start) : formatDateTimeInput(modalDate),
      end: data ? formatDateTimeInput(data?.end) : formatDateTimeInput(modalDate),
      class: data?.class,
    } ?? {});
  }, [data, reset, setValue])

  return (
    <Modal
      size="lg"
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data?.id ? 'Update Event' : 'Create Event'}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}>
          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-title">Title</Label>
                <select
                  id="formrow-title"
                  className="form-control"
                  defaultValue={data?.title}
                  onChange={(e) => setValue('title', e.target.value)}
                >
                  <option value="">Choose...</option>
                  <option value="Available">Available</option>
                  <option value="Lunch">Lunch</option>
                  <option value="Off">Off</option>
                </select>
                {errors.title && <span className="form-text text-danger">{errors.title[0]}</span>}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-title">Type</Label>
                <select
                  id="formrow-title"
                  className="form-control"
                  defaultValue={data?.all_day ?? false}
                  onChange={(e) => {
                    setAllDay(e.target.value)
                    setValue('all_day', e.target.value)
                  }}
                >
                  <option value={false}>Time Based</option>
                  <option value={true}>All Day</option>
                </select>
                {errors.all_day && <span className="form-text text-danger">{errors.all_day[0]}</span>}
              </div>
            </Col>
          </Row>

          {/* If Type is Time Based */}
          {allDay == 'true' ? null : (
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="formrow-InputDate">Start</Label>
                  <Input
                    type="datetime-local"
                    className="form-control"
                    id="formrow-InputDate"
                    pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                    placeholder="Start"
                    max="9999-12-31T23:59"
                    defaultValue={formatDateTimeInput(data ? data?.start : modalDate)}
                    onInput={(e) => setValue('date', e.target.value)}
                  />
                  {errors.start && <span className="form-text text-danger">{errors.start[0]}</span>}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="formrow-InputDate">End</Label>
                  <Input
                    type="datetime-local"
                    className="form-control"
                    id="formrow-InputDate"
                    pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                    placeholder="End"
                    max="9999-12-31T23:59"
                    defaultValue={formatDateTimeInput(data ? data?.end : modalDate)}
                    onInput={(e) => setValue('end', e.target.value)}
                  />
                  {errors.end && <span className="form-text text-danger">{errors.end[0]}</span>}
                </div>
              </Col>
            </Row >
          )}
        </Form>
      </div>
      <div className="modal-footer d-md-flex justify-content-md-between">
        <div className="d-md-flex justify-content-md-end">
          {data?.id && (
            <button type="submit" className="btn btn-danger w-md" disabled={deleteLoading} onClick={(e) => {
              e.preventDefault()
              deleteEvent();
            }}>
              {deleteLoading ? (
                <>
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                  Loading
                </>
              ) : (
                <span>Delete</span>
              )}
            </button>
          )}
        </div>
        <div className="d-md-flex justify-content-md-end">
          <button type="submit" className="btn btn-primary w-md" disabled={submitLoading} onClick={(e) => {
            e.preventDefault()
            onSubmit()
          }}>
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

CreateUpdateEvent.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  handleCreateModal: PropTypes.func,
  type: PropTypes.string,
  modalDate: PropTypes.any,
  userId: PropTypes.number,
  setSchedules: PropTypes.func,
  refetch: PropTypes.func,
};

export default CreateUpdateEvent;