import React, { useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Button, Badge } from "reactstrap"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import PropTypes from 'prop-types'
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import { currency, formatDate, formatDateTime, getRole } from "helpers/util"
import CreateUpdateAppointment from "pages/Appointment/CreateUpdateAppointment"
import CreateUpdateInvoice from "pages/Invoice/CreateUpdateInvoice";

const HistoryTable = ({ data, getData }) => {
  const [modal, setModal] = useState(false);
  const [modalInvoice, setModalInvoice] = useState(false)

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  const handleCreateModalInvoice = () => {
    toggleInvoice();
  }

  const toggleInvoice = () => {
    if (modalInvoice) {
      setModalInvoice(false);
    } else {
      setModalInvoice(true);
    }
  };

  const handleCreateModal = () => {
    toggle();
  }

  const AppointmentTable = toggleModal => [
    {
      dataField: "client.fullname",
      text: "Client",
      sort: true,
    },
    {
      dataField: "type.name_type",
      text: "Client Type",
      sort: true,
    },
    {
      dataField: "doctor.fullname",
      text: "Doctors",
      sort: true,
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => formatDate(row.date),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Badge
          className={`font-size-12 badge-soft-${!row.invoice ? 'danger' : row.invoice.status == 'PAID' ? 'success' : row.invoice.status == 'PARTIALLY' ? 'warning' : 'danger'}`}
          color={`font-size-12 badge-soft-${!row.invoice ? 'danger' : row.invoice.status == 'PAID' ? 'success' : row.invoice.status == 'PARTIALLY' ? 'warning' : 'danger'}`}
          pill
        >
          {row.invoice?.status || 'UNPAID'}
        </Badge>
      ),
    },
    {
      dataField: "approved_by",
      text: "Staff",
      sort: true,
    },
    {
      dataField: "meeting_preferences",
      text: "Meeting Via",
      sort: true,
    },
  ]

  const InvoiceTable = toggleModal => [
    {
      dataField: "user.fullname",
      text: "Client",
      sort: true,
    },
    {
      dataField: "doctor.user.fullname",
      text: "Doctor",
      sort: true,
    },
    {
      dataField: "total",
      text: "Total",
      sort: true,
      formatter: (cellContent, row) => currency(row.total),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Badge
          className={`font-size-12 badge-soft-${row.status == 'PAID' ? 'success' : row.status == 'PARTIALLY' ? 'warning' : 'danger'}`}
          color={row.status}
          pill
        >
          {row.status}
        </Badge>
      ),
    },
    {
      dataField: "created_at",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => formatDateTime(row.created_at),
    },
    {
      dataField: "approved_by",
      text: "Approved By",
      sort: true,
    },
  ]

  const pageOptionsAppointment = {
    sizePerPage: 10,
    totalSize: data.user.appointment.length, // replace later with size(orders),
    custom: true,
  }
  const pageOptionsInvoice = {
    sizePerPage: 10,
    totalSize: data.user.invoice.length, // replace later with size(orders),
    custom: true,
  }

  const selectRow = {
    mode: "checkbox",
    hideSelectColumn: true,
    hideSelectAll: true,
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="d-md-flex justify-content-md-between align-items-center">
                <CardTitle className="mb-4">Appointment History</CardTitle>
                {getRole() != 'Patient' && (
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded  mb-2 me-2"
                    onClick={() => handleCreateModal()}
                  >
                    <i className="mdi mdi-plus me-1" />
                    Add New Appointment
                  </Button>
                )}
              </div>
              <PaginationProvider
                pagination={paginationFactory(pageOptionsAppointment)}
                keyField="id"
                columns={AppointmentTable()}
                data={data.user.appointment}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    data={data.user.appointment}
                    columns={AppointmentTable()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField="id"
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        {/* <div className="pagination pagination-rounded justify-content-end">
                          <PaginationListStandalone {...paginationProps} />
                        </div> */}
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="d-md-flex justify-content-md-between align-items-center">
                <CardTitle className="mb-4">Invoice History</CardTitle>
                {getRole() != 'Patient' && (
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded  mb-2 me-2"
                    onClick={() => handleCreateModalInvoice()}
                  >
                    <i className="mdi mdi-plus me-1" />
                    Add New Invoice
                  </Button>
                )}
              </div>
              <PaginationProvider
                pagination={paginationFactory(pageOptionsInvoice)}
                keyField="id"
                columns={InvoiceTable()}
                data={data.user.invoice}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    data={data.user.invoice}
                    columns={InvoiceTable()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField="id"
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        {/* <div className="pagination pagination-rounded justify-content-end">
                          <PaginationListStandalone {...paginationProps} />
                        </div> */}
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <CreateUpdateAppointment modal={modal} toggle={toggle} refresh={getData} handleCreateModal={handleCreateModal} defaultPatient={{ label: data.user.fullname, value: data.user.id }} />
      <CreateUpdateInvoice modal={modalInvoice} toggle={toggleInvoice} handleCreateModal={handleCreateModalInvoice} refresh={getData} defaultPatient={{ label: data.user.fullname, value: data.user.id }} />
    </React.Fragment>
  )
}

HistoryTable.propTypes = {
  data: PropTypes.object,
  getData: PropTypes.func,
};

export default HistoryTable
