import React from 'react'
import { Modal, Table } from 'reactstrap'
import PropTypes from 'prop-types'
import { formatDateTime } from 'helpers/util'

const LogHistory = ({ modal, toggle, data }) => {
  return (
    <Modal
      toggle={() => toggle()}
      isOpen={modal}
      centered
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          Invoice Log History
        </h5>
        <button
          onClick={() => toggle()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Table responsive>
          <thead className="text-center bg-secondary text-light">
            <th>Log</th>
            <th>Action By</th>
            <th>Do At</th>
          </thead>
          <tbody>
            {data?.log.map(item => (
              <tr key={item.id} className="text-center">
                <td>
                  {item.log}
                </td>
                <td>
                  {item.user?.fullname}
                </td>
                <td>
                  {formatDateTime(item.created_at)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Modal>
  )
}

LogHistory.propTypes = {
  modal: PropTypes.bool,
  data: PropTypes.object,
  toggle: PropTypes.func,
};

export default React.memo(LogHistory);
