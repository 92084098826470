import React from 'react'
import PropTypes from "prop-types";
import { Col, Row, Table } from 'reactstrap'
import { useMutation } from '@tanstack/react-query';
import api from 'helpers/api';
import { showToast } from 'helpers/util';

const PaymentList = ({data, refetch}) => {
  const { mutate: removePayment } = useMutation((data) => api.removePayment(data), {
    onSuccess: function (res) {
      showToast(res.message, !res.status && 'error')
      refetch();
    }
  });

  return (
    <Row className="d-print-none">
      <Col md={6} className="mt-3">
        <div className="table-responsive">
          <Table className="table table-bordered mb-0 text-center">
            <thead>
              <tr>
                <th>#</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Last Action By</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.payment.length == 0 ? (
                <tr className="text-center">
                  <td colSpan={4}>No Data</td>
                </tr>
              ) : (
                data.payment.map((item, key) => (
                  <tr className="va-mid" key={item.id}>
                    <th scope="row">{key + 1}</th>
                    <td>${item.amount}</td>
                    <td>{item.type}</td>
                    <td>{item.user?.fullname ?? '-'}</td>
                    <td>
                      {(item.type == 'Counter' && !item.is_remove) ? (
                          <button
                            type="button"
                            className="btn btn-warning btn-sm"
                            onClick={() => removePayment(item)}
                          >
                            Remove
                          </button>
                      ) : (item.type == 'Credit Card' && !item.is_remove) ? (
                          <button
                            type="button"
                            className="btn btn-warning btn-sm"
                            onClick={() => removePayment(item)}
                          >
                            Refund
                          </button>
                      ) : (
                        <span>{item.type == 'Counter' ? 'Removed' : 'Refunded'}</span>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      </Col>
      <Col md={6} className="mt-3">
        <div className="table-responsive">
          <Table className="table table-bordered mb-0 text-center">
            <thead>
              <tr>
                <th>#</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr className="va-mid">
                <th scope="row">1</th>
                <td>$200</td>
                <td>Counter</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-warning btn-sm"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  )
}

PaymentList.propTypes = {
  data: PropTypes.object,
  refetch: PropTypes.func,
};

export default React.memo(PaymentList)